import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scr-item',
  templateUrl: './scr-item.component.html',
  styleUrls: ['./scr-item.component.less'],
  inputs: ['scr']
})
export class ScrItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
}
